<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Adoption Status</h3>

	<p>The Adoption Status of a framework will be displayed on the Table View of the Framework Index as well as in the Tree View on the framework item card. 
		<ul>
			<li>The Adoption Status can be edited by a System Admin, System Editor, Framework Admin, and Framework Editor. See <span v-html="link('admin', 'Admin Functions')"></span> user guide for more information.</li>
			<li>There is also an option to include dates for an IMPLEMENTATION START and/or RETIREMENT END date for a changing adoption status of the framework. </li>
			<ul>
				<li>Setting dates triggers additional messaging on the framework item card:</li>
			</ul>
		</ul>
		<img alt="Location of start date" srcset="/docimages/start_date.png 1.4x" class="k-help-img ">
		<img alt="Location of end date" srcset="/docimages/end_date.png 1.4x" class="k-help-img float-right">
	</p>

	<p>Each <span v-html="app_name"/> framework includes an Adoption Status tag, which usually specifies two things about the framework:
		<ul>
			<li>Whether the framework is <strong>PUBLIC or PRIVATE:</strong></li>
			<ul>
				<li>Public frameworks are available for anyone to view and print without signing in.</li>
				<li>Private frameworks are only available to signed-in users of <span v-html="app_name"/> who have been explicitly <span v-html="link('roles_framework', 'authorized')"></span> to view or manage the framework by a <strong>System Admin</strong> or <strong>Framework Admin.</strong></li>
			</ul>

			<li>The stage of adoption the framework is in:</li>
			<ul>
				<li><strong>DRAFT</strong> frameworks are still being edited (or still being reviewed for further possible edits).</li>
					<ul>
						<li>Therefore, viewers/consumers of a Draft framework should assume that some elements of the framework may change in the future.</li>
					</ul>
				<li><strong>PROPOSED</strong> frameworks are out of Draft mode—that is, the author of the framework believes it to be complete and accurate—but in this case the author of the framework is not the issuer of the learning standards represented in the framework.</li>
					<ul>
						<li>Viewers/consumers of Proposed frameworks should take into account that the issuing agency for the standards represented in the framework have not officially endorsed the framework as the official source of truth for the standards.</li>
						<li>Check the <strong>creator, notes, description,</strong> or <strong>official source URL</strong> metadata on a Proposed framework <span v-html="link('document_card', 'document')"></span> for details on the origin of a Proposed framework.</li>
					</ul>
				<li><strong>IMPLEMENTED</strong> frameworks have been deemed complete by the issuer of the learning standards represented in the framework and are being implemented..</li>
					<ul>
						<li>In some states, adopted frameworks may be made public but not expected to be in use until a future date (see IMPLEMENTATION DATE noted above). In this scenario, there may be two concurrent adopted frameworks for a given subject area.</li>
						<li>An Implemented framework should thus be considered the “single source of truth” for the standards in digital form.</li>
						<li>An Implemented framework should only be changed in the future in the event that errors are discovered and corrected. In particular, the <span v-html="link('identifiers', 'GUIDs')"></span>  for items in the framework should not change once the framework has reached its Implemented phase.</li>
						<li>Other CASE publishing tools may use adoption status “Adopted” or “Published” instead of “Implemented”; these three values should, for practical purposes, be considered equivalent.</li>
					</ul>
				<li><strong>RETIRED</strong> frameworks have been deemed out of date, expired, or retired by the author of the framework.</li>
					<ul>
						<li>Often, a framework is marked as Retired when a new version of the standards represented in the framework have been adopted.</li>
						<li>Other CASE publishing tools may use adoption status “Deprecated” instead of “Retired”; these three values should, for practical purposes, be considered equivalent.</li>
					</ul>
			</ul>
		</ul>
	</p>

	<p>Framework editors can set the Adoption Status in the <span v-html="link('edit_document', 'document editor')"></span>.</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	